import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { ASSESSMENT_MENU } from '../../helpers/constants';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
  card: {
    width: 150,
    height: 150,
    borderRadius: '100%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 8
  }
}));


const QuestionnaireMenuPage = () => {
  const classes = useStyles();

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>แบบประเมินสุขภาพ - MorOnline</title>
      </Helmet>
      <Box mt={4}>
        {
          ASSESSMENT_MENU.map(({ title, image, url }, index) => (
            <Box display='flex' flexDirection='column' alignItems='center' key={index} mb={4} onClick={() => navigate(url)}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={image}
                  />
                </CardActionArea>
              </Card>
              <p className={classes.title}>{title}</p>
            </Box>
          ))
        }
      </Box>

    </Layout>
  );
}

export default QuestionnaireMenuPage;
